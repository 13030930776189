<template>
  <div class="facilityWrap">
    <el-breadcrumb separator="/" style="margin:10px 0">
      <el-breadcrumb-item :to="{ path: '/platform/deviceManage/list' }">设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>设备操作记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="facilityBox">
      <div class="top_btn">
        <el-button size="small" @click="$router.go(-1)" icon="el-icon-arrow-left">返回</el-button>
      </div>
      <div style="display: flex; justify-content: center; margin-bottom: 30px;">
        <div style="color: #409eff;">设备编号: {{infoData.IMEI}}</div>
        <div style="margin-left: 60px; color: #409eff;">设备类型: {{infoData.EquipTypeID == '01' ? '方众手环' : '方众魔盒'}}</div>
      </div>
      <div class="fromWrap">
        <!-- 操作信息表单 -->
        <el-table :data="operatelist" :header-cell-style="{background:'#f0f0f0',color:'#666'}" height="610px" v-loading="loading">
          <el-table-column
            prop="sindex"
            align="center"
            label="操作次序"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="EquipOwnerName"
            label="设备所有人"
            width="250"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleCarNumber"
            label="绑定车牌号"
            width="100"
          ></el-table-column>
                  <el-table-column
            align="center"
            prop="CarNumberType"
            label="牌照类型"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleType"
            label="车辆类型"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleAttribType"
            label="车辆归属类型"
            width="120"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="DriverName"
            label="绑定司机"
            width="120"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="DriverPhone"
            label="司机手机号码"
            width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Operate"
            label="操作"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="OperatorName"
            label="操作人姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="OperateDate"
            label="操作时间"
            width="160"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Remark"
            label="备注说明"
          >
              <template slot-scope="scope"><div style="color: red;">{{ scope.row.Remark }}</div></template>
          </el-table-column>              
        </el-table>
      </div>
    <!-- 分页 -->
      <el-pagination
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { equipmentsInfo } from "@/api/platform/deviceManage/index";
export default {
  data() {
    return {
      // 操作信息
      operatelist: [],
      infoData: {
        IMEI: '',
        EquipTypeID: ''
      },
      facility: {},
      // 筛选条件数据
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading: false // 表格加载
    };
  },
  created() {
    this.infoData.IMEI = this.$route.query.IMEI;
    this.infoData.EquipTypeID = this.$route.query.EquipTypeID;
    this.facility.EquipNO = this.$route.query.EquipNO;
    this.facility.EquipStatus = this.$route.query.EquipStatus;
    this.facility.EquipType = this.$route.query.EquipType;
  },
  mounted() {
    this.equipmentsInfo();
  },
  methods: {
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.infoData.pageIndex = e;
      this.equipmentsInfo();
    },
    equipmentsInfo() {
      this.loading = true
        let params = {
            IMEI: this.infoData.IMEI,
            PageIndex: this.pagination.page,
            PageSize: this.pagination.pagesize
        }
      equipmentsInfo(params).then((res) => {
        let i = 1;
        res.data.DataList.forEach((item, index, arr) => {
         // item.EquipNO = this.facility.EquipNO;
         // item.EquipStatus = this.facility.EquipStatus;
        //  item.EquipType = this.facility.EquipType;
            item.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.operatelist = res.data.DataList;
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.facilityWrap {
  .top_btn{
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }
  .fromWrap {
    .operate {
      width: 20vw;
      margin: 0 0 30px 40%;
    }
  }
  .facilityTitlle {
    width: 100%;
    text-align: center;
    font-size: 26px;
    margin: 0 0 30px 0;
  }
  .facilityTop {
    display: flex;
    justify-content: flex-start;
  }
}
</style>